import Icon from '@ant-design/icons';

const LinkedInSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 21.3 20.9">
        <switch id="switch18">
            <g id="g16">
                <g id="g14">
                    <g id="g8">
                        <path
                            d="M4.9,20.9H0.8c-0.2,0-0.4-0.2-0.4-0.4V7.1c0-0.2,0.2-0.4,0.4-0.4h4.1c0.2,0,0.4,0.2,0.4,0.4v13.4      C5.3,20.7,5.1,20.9,4.9,20.9z M1.2,20.1h3.3V7.5H1.2V20.1z M2.8,5.7c-0.8,0-1.5-0.3-2-0.8C0.3,4.3,0,3.6,0,2.8      c0-0.8,0.3-1.5,0.8-2C1.4,0.3,2.1,0,2.8,0c0.8,0,1.5,0.3,2,0.8c0.6,0.6,0.8,1.2,0.8,2c0,0.8-0.3,1.5-0.8,2      C4.3,5.4,3.6,5.7,2.8,5.7z M2.8,0.9c-0.6,0-1,0.2-1.4,0.6S0.9,2.3,0.9,2.8s0.2,1,0.6,1.4c0.8,0.8,2,0.8,2.8,0      c0.4-0.4,0.6-0.8,0.6-1.4c0-0.6-0.2-1-0.6-1.4C3.8,1,3.4,0.9,2.8,0.9z"
                            id="path6"
                        />
                    </g>
                    <g id="g12">
                        <path
                            d="M20.8,20.9h-4.1c-0.2,0-0.4-0.2-0.4-0.4v-6.6c0-0.4,0-0.8,0-1.2c0-0.4-0.1-0.7-0.2-1c-0.1-0.3-0.3-0.5-0.5-0.7      c-0.2-0.2-0.5-0.2-1-0.2c-0.5,0-0.8,0.1-1.1,0.2c-0.3,0.2-0.5,0.4-0.6,0.6c-0.1,0.3-0.2,0.6-0.3,0.9c-0.1,0.4-0.1,0.8-0.1,1.2      v6.7c0,0.2-0.2,0.4-0.4,0.4H7.9c-0.2,0-0.4-0.2-0.4-0.4V7.1c0-0.2,0.2-0.4,0.4-0.4h4c0.2,0,0.4,0.2,0.4,0.4v0.6      c0.2-0.2,0.5-0.4,0.8-0.6c0.4-0.2,0.8-0.4,1.2-0.5c0.5-0.1,1-0.2,1.5-0.2c1.1,0,2,0.2,2.7,0.5c0.7,0.4,1.3,0.9,1.7,1.5      c0.4,0.6,0.7,1.3,0.8,2.2c0.1,0.8,0.2,1.7,0.2,2.6v7.4C21.3,20.7,21.1,20.9,20.8,20.9z M17.1,20.1h3.3v-7c0-0.9-0.1-1.7-0.2-2.4      c-0.1-0.7-0.3-1.3-0.7-1.9c-0.3-0.5-0.8-0.9-1.3-1.2c-0.6-0.3-1.4-0.4-2.4-0.4c-0.5,0-0.9,0.1-1.3,0.2c-0.4,0.1-0.7,0.3-1,0.5      C13.3,8,13,8.2,12.8,8.4c-0.2,0.2-0.4,0.5-0.5,0.7c-0.2,0.4-0.9,0.2-0.9-0.2V7.5H8.3v12.6h3.3v-6.3c0-0.5,0-0.9,0.1-1.3      c0.1-0.5,0.2-0.9,0.4-1.2c0.2-0.4,0.5-0.7,0.9-0.9c0.4-0.2,0.9-0.3,1.5-0.3c0.6,0,1.1,0.1,1.5,0.4c0.3,0.3,0.6,0.6,0.8,1      c0.2,0.4,0.2,0.8,0.3,1.3c0,0.4,0,0.8,0,1.2V20.1z"
                            id="path10"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const LinkedInIcon = (props) => <Icon component={LinkedInSvg} {...props} />;

export default LinkedInIcon;
