import Icon from '@ant-design/icons';

const BookSVG = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 40 40">
        <path d="M34.79 10.744a.715.715 0 0 0-.505-.209h-.002l-2.141.008V7.679a.714.714 0 0 0-.714-.714h-.894c-5.365 0-8.508.778-10.535 2.659-2.027-1.881-5.17-2.659-10.535-2.659h-.893a.714.714 0 0 0-.714.714v2.864l-2.141-.008A.717.717 0 0 0 5 11.25v18.393c0 .395.32.714.715.714h.893c9.612 0 11.666 2.169 11.869 2.419.136.163.338.26.553.26h1.94a.722.722 0 0 0 .554-.26c.202-.25 2.257-2.419 11.869-2.419h.893a.714.714 0 0 0 .715-.714V11.25a.723.723 0 0 0-.211-.506zM6.429 28.929V11.966l1.429.006v16.97a56.18 56.18 0 0 0-1.25-.013h-.179zm2.857.059V8.393h.179c5.221 0 8.101.738 9.82 2.534v20.632c-.946-.869-3.471-2.268-9.999-2.571zm11.429 2.571V10.927c1.718-1.796 4.6-2.534 9.82-2.534h.179V28.988c-6.53.303-9.053 1.702-9.999 2.571zm12.856-2.63h-.179c-.431 0-.846.004-1.25.013v-16.97l1.429-.006v16.963z" />
    </svg>
);

const BookIcon = (props) => <Icon component={BookSVG} {...props} />;

export default BookIcon;
