import getConfig from 'next/config';

const parseCategoryUrl = (category: Category): string => {
    const { name } = getConfig().publicRuntimeConfig;
    if (category.parent && category.parent.title !== name) {
        return `/${category.parent.seoSlug}/${category.seoSlug}`;
    }

    return `/${category.seoSlug}`;
};

export default parseCategoryUrl;
