import Icon from '@ant-design/icons';

const UserMaleSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 102.3 133.7">
        <switch id="switch56">
            <g id="g54">
                <g id="g52">
                    <g id="g8">
                        <path
                            d="M52.1,135.7c-0.2,0-0.4-0.1-0.6-0.3L41.1,125c-0.2-0.2-0.3-0.5-0.2-0.8l6.9-34.4c0.1-0.5,0.5-0.8,1-0.7      c0.5,0.1,0.8,0.5,0.7,1l-6.8,33.9l9.5,9.5l9.5-9.5l-6.8-33.9c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7l6.9,34.4      c0.1,0.3,0,0.6-0.2,0.8l-10.4,10.5C52.5,135.6,52.3,135.7,52.1,135.7z"
                            id="path6"
                        />
                    </g>
                    <g id="g12">
                        <path
                            d="M52.1,93.3c-0.2,0-0.4-0.1-0.5-0.2l-8.5-6.3c-0.4-0.3-0.5-0.8-0.2-1.2c0.3-0.4,0.8-0.5,1.2-0.2l8,5.9l8.3-6.2      c0.4-0.3,0.9-0.2,1.2,0.2c0.3,0.4,0.2,0.9-0.2,1.2l-8.8,6.6C52.4,93.2,52.2,93.3,52.1,93.3z"
                            id="path10"
                        />
                    </g>
                    <g id="g16">
                        <path
                            d="M83.4,113.3H69.1c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h14.3c0.5,0,0.9,0.4,0.9,0.9S83.9,113.3,83.4,113.3z"
                            id="path14"
                        />
                    </g>
                    <g id="g50">
                        <g id="g22">
                            <g id="g20">
                                <path
                                    d="M90.5,83.9c-0.2,0-0.4-0.1-0.5-0.2c-1.8-1.3-3.5-2.2-5.2-2.6l-16.6-4.8c-0.4-0.1-0.9-0.3-1.2-0.6        c-1.1-0.7-1.8-1.9-1.9-3.2L64.1,66c0-0.3,0.1-0.6,0.3-0.8c4.5-3.5,7.8-8.7,9.4-14.8c0.1-0.3,0.4-0.6,0.7-0.6        c2.5-0.2,4.5-3.2,4.5-6.9c0-3.4-1.6-6.2-3.9-6.8c-0.5-0.1-0.7-0.6-0.6-1c0.1-0.5,0.6-0.7,1-0.6c3.1,0.8,5.2,4.2,5.2,8.4        c0,4.3-2.3,7.8-5.4,8.5c-1.7,6-5,11.3-9.5,14.9l0.7,6.1c0.1,0.8,0.5,1.5,1.2,2c0.2,0.2,0.5,0.3,0.8,0.4l16.6,4.8        C87,80,89,80.9,91,82.4c0.4,0.3,0.5,0.8,0.2,1.2C91,83.8,90.7,83.9,90.5,83.9z"
                                    id="path18"
                                />
                            </g>
                        </g>
                        <g id="g32">
                            <g id="g26">
                                <path
                                    d="M0.9,125.3c0,0-0.1,0-0.1,0c-0.5-0.1-0.8-0.5-0.7-1l3.9-30.4c0.9-4.5,3.3-8.6,6.9-11.5c2.3-1.9,4.6-3.1,6.8-3.6        L34,74.1c0.3-0.1,0.5-0.2,0.8-0.4c0.7-0.5,1.1-1.2,1.2-2l0.7-6.1c-4.4-3.6-7.8-8.9-9.5-14.9c-3.1-0.7-5.4-4.2-5.4-8.5        c0-4.1,2.1-7.5,5-8.4c0.5-0.1,0.9,0.1,1.1,0.6s-0.1,0.9-0.6,1.1c-2.2,0.6-3.8,3.4-3.8,6.7c0,3.7,1.9,6.6,4.4,6.9        c0.4,0,0.7,0.3,0.7,0.6c1.6,6.1,4.9,11.3,9.4,14.8c0.2,0.2,0.4,0.5,0.3,0.8l-0.8,6.6c-0.1,1.3-0.9,2.5-1.9,3.2        c-0.4,0.3-0.8,0.5-1.2,0.6L18,80.5c-2.1,0.5-4.1,1.5-6.2,3.2c-3.2,2.7-5.5,6.4-6.3,10.5l-3.9,30.3        C1.6,125,1.3,125.3,0.9,125.3z"
                                    id="path24"
                                />
                            </g>
                            <g id="g30">
                                <path
                                    d="M100.9,125.3c-0.4,0-0.8-0.3-0.8-0.7l-3.9-30.4c-0.8-4-3-7.7-6.3-10.4c-0.4-0.3-0.4-0.8-0.1-1.2        c0.3-0.4,0.8-0.4,1.2-0.1c3.5,2.9,6,7,6.9,11.4l3.9,30.4c0.1,0.5-0.3,0.9-0.7,1C101,125.3,100.9,125.3,100.9,125.3z"
                                    id="path28"
                                />
                            </g>
                        </g>
                        <g id="g36">
                            <path
                                d="M28.6,36.3c-0.4,0-0.8-0.3-0.8-0.8c0-0.4-0.6-9.5,3-14.8c0.3-0.4,0.8-0.5,1.2-0.2c0.4,0.3,0.5,0.8,0.2,1.2       c-3.3,4.8-2.7,13.6-2.7,13.7C29.5,35.8,29.1,36.2,28.6,36.3C28.7,36.3,28.6,36.3,28.6,36.3z"
                                id="path34"
                            />
                        </g>
                        <g id="g40">
                            <path
                                d="M75.4,36.2C75.4,36.2,75.3,36.2,75.4,36.2c-0.5,0-0.9-0.4-0.8-0.9c0-0.1,0.2-5.2-1.5-10.8c-0.1-0.5,0.1-0.9,0.6-1.1       c0.4-0.1,0.9,0.1,1.1,0.6c1.8,6,1.5,11.2,1.5,11.4C76.2,35.9,75.8,36.2,75.4,36.2z"
                                id="path38"
                            />
                        </g>
                        <g id="g44">
                            <path
                                d="M52.1,83.9c-0.2,0-0.4-0.1-0.6-0.2L38,71.6c-0.3-0.3-0.4-0.9-0.1-1.2c0.3-0.4,0.8-0.4,1.2-0.1l12.9,11.6L65,70.3       c0.4-0.3,0.9-0.3,1.2,0.1c0.3,0.3,0.3,0.9-0.1,1.2L52.6,83.7C52.5,83.8,52.3,83.9,52.1,83.9z"
                                id="path42"
                            />
                        </g>
                        <g id="g48">
                            <path
                                d="M64.9,22.2c-1,0-1.9-0.1-2.8-0.2c-2.4-0.3-4.8-0.9-7.1-1.4c-1.5-0.3-3-0.7-4.4-1c-4.6-0.9-8.1-0.9-11.2,0.1       c-0.4,0.1-0.9-0.1-1.1-0.6c-0.1-0.4,0.1-0.9,0.6-1.1c3.3-1,7.1-1.1,12-0.1c1.5,0.3,3,0.7,4.5,1c2.2,0.5,4.6,1.1,6.9,1.4       c1.6,0.2,3.6,0.4,5.4-0.2c2.4-0.7,4.6-2.8,5.8-5.6c1-2.3,1.1-7.2,1-10.3c-4.4,1.6-8.3,1.8-12.6,0.6c-0.2-0.1-0.4-0.2-0.5-0.4       s-0.1-0.5,0-0.7c0.2-0.6,0.3-1.3,0.1-1.9C57,3.5,51.3,4.3,49,4.6l-0.4,0.1c-7.8,1-16.2,6.6-16.3,16.5c0,0.5-0.4,0.8-0.9,0.8       c0,0,0,0,0,0c-0.5,0-0.8-0.4-0.8-0.9C30.7,10.2,39.9,4,48.4,2.9l0.4-0.1c2.3-0.3,8.5-1.1,12.7-2.8c0.4-0.2,0.8,0,1,0.3       c0.6,1,0.8,2.1,0.6,3.1c4,0.9,7.6,0.5,11.9-1.2c0.3-0.1,0.6-0.1,0.8,0.1c0.2,0.2,0.4,0.4,0.4,0.7c0.1,3,0.2,9.2-1.1,12.2       c-1.4,3.3-3.9,5.6-6.9,6.5C67.1,22.1,65.9,22.2,64.9,22.2z"
                                id="path46"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const UserMaleIcon = (props) => <Icon component={UserMaleSvg} {...props} />;

export default UserMaleIcon;
