import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const LeftCircleOutlinedSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 28.8 28.8">
        <switch id="switch18">
            <g id="g16">
                <g id="g14">
                    <g id="g8">
                        <path
                            d="M14.4,28.8C6.5,28.8,0,22.4,0,14.4S6.5,0,14.4,0s14.4,6.5,14.4,14.4S22.4,28.8,14.4,28.8z M14.4,0.9      C6.9,0.9,0.8,6.9,0.8,14.4S6.9,28,14.4,28S28,21.9,28,14.4S21.9,0.9,14.4,0.9z"
                            id="path6"
                        />
                    </g>
                    <g id="g12">
                        <path
                            d="M15.9,20.6c-0.1,0-0.2,0-0.3-0.1l-5.7-5.7c-0.1-0.1-0.1-0.2-0.1-0.3s0-0.2,0.1-0.3l5.7-5.7c0.2-0.2,0.4-0.2,0.6,0      s0.2,0.4,0,0.6l-5.4,5.4l5.4,5.4c0.2,0.2,0.2,0.4,0,0.6C16.1,20.5,16,20.6,15.9,20.6z"
                            id="path10"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

type Props = Partial<CustomIconComponentProps>;

const LeftCircleOutlinedIcon = (props: Props) => (
    <Icon component={LeftCircleOutlinedSvg} {...props} />
);

export default LeftCircleOutlinedIcon;
