import LeftCircleOutlinedIcon from './LeftCircleOutlined';
import RightCircleOutlinedIcon from './RightCircleOutlined';

import FacebookIcon from './Facebook';
import LinkedInIcon from './LinkedIn';
import TwitterIcon from './Twitter';
import YouTubeIcon from './YouTube';

import QuotemarkIcon from './Quotemark';

import UserMaleIcon from './UserMale';
import UserFemaleIcon from './UserFemale';
import BookIcon from './Book';

export {
    LeftCircleOutlinedIcon,
    RightCircleOutlinedIcon,
    FacebookIcon,
    LinkedInIcon,
    QuotemarkIcon,
    TwitterIcon,
    YouTubeIcon,
    UserFemaleIcon,
    UserMaleIcon,
    BookIcon
};
