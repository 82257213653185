import Icon from '@ant-design/icons';

const FacebookSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 11.3 22.1">
        <switch id="switch14">
            <g id="g12">
                <g id="g10">
                    <g id="g8">
                        <path
                            d="M7.3,22.1H3.2c-0.2,0-0.4-0.2-0.4-0.4V12H0.4C0.2,12,0,11.8,0,11.5V8.2C0,8,0.2,7.8,0.4,7.8h2.3v-2      c0-0.8,0.1-1.5,0.2-2.2C3.1,3,3.4,2.3,3.8,1.8c0.4-0.6,1-1,1.8-1.3C6.3,0.2,7.2,0,8.4,0c0.4,0,0.9,0,1.3,0.1      c0.4,0,0.8,0.1,1.3,0.2c0.2,0,0.3,0.2,0.3,0.4l-0.2,3.5c0,0.1-0.1,0.3-0.2,0.3c-0.1,0.1-0.3,0.1-0.4,0c-0.2-0.1-0.4-0.1-0.6-0.2      c-0.2,0-0.4-0.1-0.6-0.1c-0.6,0-1,0.1-1.2,0.3C8,4.8,7.7,5.1,7.7,6.1v1.7h2.6c0.2,0,0.4,0.2,0.4,0.4v3.3c0,0.2-0.2,0.4-0.4,0.4      H7.7v9.7C7.7,21.9,7.5,22.1,7.3,22.1z M3.6,21.2h3.3v-9.7c0-0.2,0.2-0.4,0.4-0.4H10V8.6H7.3c-0.2,0-0.4-0.2-0.4-0.4V6.1      c0-1,0.2-1.7,0.6-2.1c0.4-0.4,1-0.5,1.8-0.5c0.3,0,0.6,0,0.8,0.1c0.1,0,0.1,0,0.2,0L10.4,1c-0.3,0-0.5-0.1-0.8-0.1      c-0.4,0-0.8-0.1-1.2-0.1C7.4,0.9,6.5,1,5.9,1.2c-0.6,0.3-1.1,0.6-1.4,1c-0.3,0.4-0.6,1-0.7,1.6c-0.1,0.6-0.2,1.3-0.2,2v2.4      c0,0.2-0.2,0.4-0.4,0.4H0.9v2.5h2.3c0.2,0,0.4,0.2,0.4,0.4V21.2z"
                            id="path6"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const FacebookIcon = (props) => <Icon component={FacebookSvg} {...props} />;

export default FacebookIcon;
