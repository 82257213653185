import Icon from '@ant-design/icons';

const QuotemarkSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 30.2 23.3">
        <switch id="switch14">
            <g id="g12">
                <g id="g10">
                    <g id="g8">
                        <path
                            d="M24.2,0c1.9,0,3.3,0.7,4.4,2c1.1,1.3,1.6,3.1,1.6,5.3c0,3.1-0.9,6-2.6,8.6c-1.7,2.6-4.5,5.1-8.2,7.5      l-1.9-2.2c2.5-2,4.5-4,5.9-5.8c1.4-1.8,2.2-3.4,2.3-4.8l-0.3-0.3c-0.1,0.2-0.4,0.4-0.8,0.6c-0.4,0.1-0.9,0.2-1.4,0.2      c-1.3,0-2.4-0.5-3.3-1.5c-0.8-1-1.3-2.3-1.3-3.9c0-1.7,0.5-3.1,1.6-4.1C21.2,0.5,22.5,0,24.2,0z M6.7,0c1.9,0,3.3,0.7,4.4,2      c1.1,1.3,1.6,3.1,1.6,5.3c0,3.1-0.9,6-2.6,8.6c-1.7,2.6-4.5,5.1-8.2,7.5L0,21.2c2.5-2,4.5-4,5.9-5.8C7.3,13.6,8,12,8.1,10.6      l-0.4-0.3c-0.1,0.2-0.4,0.4-0.8,0.6c-0.4,0.1-0.9,0.2-1.4,0.2c-1.3,0-2.4-0.5-3.3-1.5C1.5,8.6,1,7.3,1,5.7C1,4,1.6,2.6,2.6,1.6      C3.7,0.5,5,0,6.7,0z"
                            id="path6"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const QuotemarkIcon = (props) => <Icon component={QuotemarkSvg} {...props} />;

export default QuotemarkIcon;
