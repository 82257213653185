import Icon from '@ant-design/icons';

const UserFemaleSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 102.3 133.7">
        <switch id="switch38">
            <g id="g36">
                <g id="g34">
                    <g id="g8">
                        <path
                            d="M51.2,133.7c-0.2,0-0.4-0.1-0.6-0.3L40.2,123c-0.2-0.2-0.3-0.5-0.2-0.8l6.9-34.4c0.1-0.5,0.5-0.8,1-0.7      c0.5,0.1,0.8,0.5,0.7,1l-6.8,33.9l9.5,9.5l9.5-9.5l-6.8-33.9c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7l6.9,34.4      c0.1,0.3,0,0.6-0.2,0.8l-10.4,10.5C51.6,133.6,51.4,133.7,51.2,133.7z"
                            id="path6"
                        />
                    </g>
                    <g id="g12">
                        <path
                            d="M101.5,122.7c-0.4,0-0.8-0.3-0.8-0.7l-3.9-29.8c-0.8-4.1-3.1-7.9-6.3-10.6c-2.1-1.7-4.1-2.8-6.1-3.2l-16.8-4.9      c-0.5-0.1-0.9-0.3-1.3-0.6c-1.1-0.7-1.8-1.9-2-3.2L64,68.4c-0.4-3.3,0.5-6.5,2.5-9c2.6-3.2,3.8-6.4,4-11.1      c0-3.3-0.5-8.1-1.5-14.2c-0.1-0.5,0.2-0.9,0.7-1c0.5-0.1,0.9,0.2,1,0.7c1.1,6.3,1.6,11.2,1.5,14.6c-0.2,5-1.5,8.7-4.4,12.1      c-1.7,2.1-2.5,4.9-2.2,7.7l0.2,1.4c0.1,0.8,0.5,1.5,1.2,2c0.2,0.2,0.5,0.3,0.8,0.4l16.8,4.9c2.2,0.5,4.5,1.7,6.8,3.6      c3.6,2.9,6,7.1,6.9,11.6l3.9,29.8c0.1,0.5-0.3,0.9-0.7,1C101.5,122.7,101.5,122.7,101.5,122.7z"
                            id="path10"
                        />
                    </g>
                    <g id="g16">
                        <path
                            d="M76.6,63.3c-1.3,0-2.6-0.1-4-0.4c-0.5-0.1-0.8-0.5-0.7-1c0.1-0.5,0.5-0.8,1-0.7c10.8,1.8,16.8-4.5,18.8-7.1      c-4.4-0.9-7.7-3.5-10-7.8c-2.7-5.1-3.2-10.2-3.8-15.7c-0.1-0.7-0.2-1.5-0.2-2.2c-0.5-4.2-1.2-8.6-3-12.6      C70.8,7.5,61.2,1.7,51.2,1.7c-10.1,0-19.7,5.8-23.4,14.1c-1.8,4-2.5,8.4-3,12.6c-0.1,0.7-0.2,1.5-0.2,2.2      c-0.6,5.5-1.2,10.7-3.8,15.7c-2.3,4.3-5.6,6.9-10,7.8c2,2.6,8,9,18.8,7.1c0.5-0.1,0.9,0.2,1,0.7s-0.2,0.9-0.7,1      c-14.4,2.5-21.2-8.9-21.2-9c-0.1-0.2-0.2-0.6,0-0.8c0.1-0.3,0.4-0.4,0.7-0.5c4.5-0.5,7.8-2.9,10.1-7.1c2.5-4.8,3.1-9.8,3.7-15.1      c0.1-0.7,0.2-1.5,0.2-2.2c0.5-4.3,1.2-8.9,3.1-13.1C30.2,6.2,40.5,0,51.2,0c10.7,0,21,6.2,24.9,15.1c1.9,4.2,2.6,8.8,3.1,13.1      c0.1,0.8,0.2,1.5,0.2,2.2c0.6,5.3,1.1,10.4,3.7,15.1c2.3,4.2,5.6,6.6,10.1,7.1c0.3,0,0.5,0.2,0.7,0.5c0.1,0.3,0.1,0.6,0,0.8      C93.8,54.1,88.2,63.3,76.6,63.3z"
                            id="path14"
                        />
                    </g>
                    <g id="g20">
                        <path
                            d="M51.2,84.7c-0.2,0-0.4-0.1-0.5-0.2L35.2,73c-0.4-0.3-0.5-0.8-0.2-1.2c0.3-0.4,0.8-0.5,1.2-0.2l14.9,11.2l14.9-11.2      c0.4-0.3,0.9-0.2,1.2,0.2c0.3,0.4,0.2,0.9-0.2,1.2L51.7,84.5C51.5,84.6,51.3,84.7,51.2,84.7z"
                            id="path18"
                        />
                    </g>
                    <g id="g24">
                        <path
                            d="M51.2,91.3c-0.2,0-0.4-0.1-0.5-0.2l-8.5-6.3c-0.4-0.3-0.5-0.8-0.2-1.2c0.3-0.4,0.8-0.5,1.2-0.2l8,5.9l8.3-6.2      c0.4-0.3,0.9-0.2,1.2,0.2c0.3,0.4,0.2,0.9-0.2,1.2l-8.8,6.6C51.5,91.2,51.3,91.3,51.2,91.3z"
                            id="path22"
                        />
                    </g>
                    <g id="g28">
                        <path
                            d="M82.3,111.3H68c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h14.3c0.5,0,0.9,0.4,0.9,0.9S82.8,111.3,82.3,111.3z"
                            id="path26"
                        />
                    </g>
                    <g id="g32">
                        <path
                            d="M0.9,122.7c0,0-0.1,0-0.1,0c-0.5-0.1-0.8-0.5-0.7-1L3.9,92c0.9-4.6,3.4-8.7,6.9-11.6c2.3-1.9,4.6-3.1,6.9-3.6l16.7-4.9      c0.3-0.1,0.5-0.2,0.8-0.4c0.7-0.5,1.1-1.2,1.2-2l0.2-1.4c0.3-2.8-0.5-5.6-2.2-7.7c-2.8-3.5-4.1-7.1-4.4-12.1      c0-4.9,1.1-12.1,2-17.2c0.9-5.2,4.4-9.5,9.2-11.6c4-1.8,8.8-0.7,11.5,2.7c0.8,1,1.5,2,2.1,3c0.9,1.4,1.8,2.7,2.9,3.8      c3,3.1,7.6,4.6,11.8,3.9c0.5-0.1,0.9,0.2,1,0.7c0.1,0.5-0.2,0.9-0.7,1c-4.8,0.8-9.9-0.9-13.3-4.4c-1.2-1.2-2.2-2.7-3.1-4.1      c-0.6-0.9-1.3-1.9-2-2.8c-2.3-2.8-6.2-3.7-9.6-2.2c-4.3,1.9-7.3,5.8-8.2,10.4c-0.9,5.1-2,12.2-2,16.9c0.2,4.6,1.4,7.9,4,11.1      c2,2.4,2.9,5.7,2.5,9l-0.2,1.4c-0.2,1.3-0.9,2.5-2,3.2c-0.4,0.3-0.8,0.5-1.3,0.6l-16.8,4.9C16.1,78.9,14,80,12,81.7      c-3.3,2.7-5.5,6.5-6.3,10.6L1.7,122C1.6,122.4,1.3,122.7,0.9,122.7z"
                            id="path30"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const UserFemaleIcon = (props) => <Icon component={UserFemaleSvg} {...props} />;

export default UserFemaleIcon;
