import Icon from '@ant-design/icons';

const YouTubeSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 29.2 21.4">
        <switch id="switch18">
            <g id="g16">
                <g id="g14">
                    <g id="g8">
                        <path
                            d="M22.9,21.4H6.2c-3.4,0-6.2-2.8-6.2-6.2v-9C0,2.8,2.8,0,6.2,0h16.7c3.4,0,6.2,2.8,6.2,6.2v9      C29.2,18.6,26.4,21.4,22.9,21.4z M6.2,0.9c-3,0-5.4,2.4-5.4,5.4v9c0,3,2.4,5.4,5.4,5.4h16.7c3,0,5.4-2.4,5.4-5.4v-9      c0-3-2.4-5.4-5.4-5.4H6.2z"
                            id="path6"
                        />
                    </g>
                    <g id="g12">
                        <path
                            d="M10.8,15.5c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.2-0.2-0.4V6.3c0-0.2,0.1-0.3,0.2-0.4c0.1-0.1,0.3-0.1,0.4,0l7.6,4.4      c0.1,0.1,0.2,0.2,0.2,0.4s-0.1,0.3-0.2,0.4L11,15.4C11,15.5,10.9,15.5,10.8,15.5z M11.2,7.1v7.2l6.3-3.6L11.2,7.1z"
                            id="path10"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const YouTubeIcon = (props) => <Icon component={YouTubeSvg} {...props} />;

export default YouTubeIcon;
