import Icon from '@ant-design/icons';

const RightCircleOutlinedSvg = () => (
    <svg width="1em" height="1em" fill="currentColor" viewBox="0 0 28.8 28.8">
        <switch id="switch18">
            <g id="g16">
                <g id="g14">
                    <g id="g8">
                        <path
                            d="M14.4,28.8C6.5,28.8,0,22.4,0,14.4S6.5,0,14.4,0s14.4,6.5,14.4,14.4S22.4,28.8,14.4,28.8z M14.4,0.9      C6.9,0.9,0.8,6.9,0.8,14.4S6.9,28,14.4,28S28,21.9,28,14.4S21.9,0.9,14.4,0.9z"
                            id="path6"
                        />
                    </g>
                    <g id="g12">
                        <path
                            d="M13,20.6c-0.1,0-0.2,0-0.3-0.1c-0.2-0.2-0.2-0.4,0-0.6l5.4-5.4L12.7,9c-0.2-0.2-0.2-0.4,0-0.6s0.4-0.2,0.6,0l5.7,5.7      c0.2,0.2,0.2,0.4,0,0.6l-5.7,5.7C13.2,20.5,13.1,20.6,13,20.6z"
                            id="path10"
                        />
                    </g>
                </g>
            </g>
        </switch>
    </svg>
);

const RightCircleOutlinedIcon = (props) => <Icon component={RightCircleOutlinedSvg} {...props} />;

export default RightCircleOutlinedIcon;
